import { Injectable } from '@angular/core';
import { BASE_URL,API_URL, objectToParams,API_URL_FOR_BRANDING } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RestService {


  constructor(private _http: HttpClient, private route: ActivatedRoute) { }

  Delete(resultUrl: string){

    const apiUrl = BASE_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'X-TimeZone': this.GetXTimeZone().toString(),
        'Content-Type': 'application/json-patch+json; charset=UTF-8',
      })
    };

    return this._http.delete<HttpResponse<Object>>(url, httpOptions).pipe();
  }


  Put(resultUrl: string, requestParameterData: any){

    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'X-TimeZone': this.GetXTimeZone().toString(),
        'Content-Type': 'application/json',
      })
    };

    return this._http.put<HttpResponse<Object>>(url, requestParameterData, httpOptions).pipe();
  }


  Get(resultUrl: string,isBranding:any = false){
    var url = (isBranding ? API_URL_FOR_BRANDING : API_URL) + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'X-TimeZone': this.GetXTimeZone().toString(),
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };
    
    return this._http.get<HttpResponse<Object>>(url, httpOptions).pipe(tap());
  }


  Post(resultUrl: string, requestParameterData: any, timeout?: any){
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'X-TimeZone': this.GetXTimeZone().toString(),
        'Content-Type': 'application/json',
      })
    };

    return this._http.post<HttpResponse<Object>>(url,requestParameterData, httpOptions).pipe(tap());
  }

  PostFile(resultUrl: string, requestParameterData: any, timeout?: any){
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<Object>>(url,requestParameterData, httpOptions).pipe(tap());
  }

  Token(request) {
    var url = BASE_URL + '/Token';
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      })
    };

    return this._http.post<HttpResponse<Object>>(url,request, httpOptions).pipe(tap());
  }

  GetUserProfile(){
    var url="/Users/GetUsersDetails";
    return this.Get(url);
  }
  
  GetCompanyList() {
    var url="/Company/GetCompanyList";
    return this.Get(url);
  }
  ForgotPassword(request){
    var url="/Common/ForgotPassword";
    return this.Post(url,request);
  }

  VerifyForgotPassword(request){
    var url="/Common/VerifyForgotPassword";
    return this.Post(url,request);
  }

  VerifyUser() {
    var url="/Users/VerifyUser";
    return this.Get(url);
  }

  Getblob(resultUrl: string) {
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'X-TimeZone': this.GetXTimeZone().toString(),
        'Content-Type': 'application/json',
      })
    };

    return this._http.get<HttpResponse<Object>>(url, httpOptions).pipe(tap());
  }
  Postblob(resultUrl: string, req:any) {
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'X-TimeZone': moment().utcOffset().toString(),
        'Content-Type': 'application/json',
      })
    };

    return this._http.post<HttpResponse<Object>>(url, req, httpOptions).pipe(tap());
  }

  GetXTimeZone(){
    var date = new Date();
    var utcOffset = moment(date).utcOffset();
    if(moment(date).isDST()){
      console.log('dst');
      utcOffset = utcOffset - 60;
    }else{
      console.log('non-dst');
    }
    console.log(utcOffset);
    return utcOffset;
  }

  GetBackGroundImage(){
    var url="/CompanyBranding/GetBackGroundImage";
    return this.Get(url,true);
  }
  saveFile(blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) { 
        navigator.msSaveBlob(blob, fileName);
    } else { 
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    }

  }
}
