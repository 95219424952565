import { Injectable } from '@angular/core';
import { RestService } from '../../../shared/rest.service';
import { request } from 'http';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private _restService: RestService) { }

  GetTransactionReport(request: any) {
    let _url = '/Report/GetTransactionReport';
    return this._restService.Post(_url, request);
  }
  GetScheduleTransactionsReport(request: any) {
    let _url = '/Report/GetScheduleTransactionsReport';
    return this._restService.Post(_url, request);
  }
  UpcomingScheduleTransactionsReport(request: any) {
    let _url = '/Report/UpcomingScheduleTransactionsReport';
    return this._restService.Post(_url, request);
  }
  ExecutedScheduleTransactionsReport(executedrequest: any) {
    let _url = '/Report/ExecutedScheduleTransactionsReport';
    return this._restService.Post(_url, executedrequest);
  }

  GetAPIUser(request: any) {
    let _url = '/Users/GetAPIUser' + request;
    return this._restService.Get(_url);
  }

  GetActivityLog(request: any) {
    let _url = '/ActivityLog/GetActivityLog';
    return this._restService.Post(_url, request);
  }
  GetActivityLogName(request: any) {
    let _url = '/ActivityLog/GetActivityLogName';
    return this._restService.Post(_url, request);
  }
    GetCustomerBalanceList(request: any) {
        let _url = '/Customer/GetCustomersWithBalance';
        return this._restService.Post(_url, request);
  }
  GetCambridgePaymentOrderDetails(request:any) {
    let _url = '/Report/GetCambridgePaymentOrderDetails';
    return this._restService.Post(_url, request);
  }
  GetACHTranscationList(request:any) {
    let _url = '/ScheduleTransaction/GetScheduleTransactionsCount';
    return this._restService.Post(_url, request);
  }
  GetSettingCompanyByCategoryKey(key:any,id:any) {
    let _url = '/Setting/GetSettingCompanyByCategoryKey?categoryKey=cambridgeschedule&companyId='+id;
    return this._restService.Get(_url);
  }
  GetTimezones(){
    let _url = '/Utils/GetTimeZones';
    return this._restService.Post(_url,'');
  }
  SaveUpdateFrequency(request){
    let _url = '/Cambridge/SaveUpdateCorpayFrequency';
    return this._restService.Post(_url,request);
  }
  GetActiveAccountsReport(request){
    let _url = '/Report/GetActiveAccountsReport'
    return this._restService.Post(_url, request)
  }
  GetNewEwalletSetupsReport(request){
    let _url = '/Report/GetNewEwalletSetupsReport'
    return this._restService.Post(_url, request)
  }
  GetSpendbackFeeReport(request){
    let _url = '/Report/GetSpendbackFeeReport'
    return this._restService.Post(_url, request)
  }
  GetCompanyDetail() {
    let _url = '/Company/GetCompanyDetail';
    return this._restService.Get(_url);
}
 GetFeeReportByCompany(request){
  let _url = '/Report/GetFeeReportByCompany'
  return this._restService.Post(_url, request)
 }
 GetFeeReportDetailByCompany(request){
  let _url = '/Report/GetFeeReportDetailByCompany'
  return this._restService.Post(_url, request)
 }
    GetCustomerBalanceExportList() {
        let _url = '/Customer/GetExportFiles'
        return this._restService.Post(_url, {})
    }
    SaveUpdateScheduleDateTime(request){
      let _url = '/ScheduleTransaction/UpdateScheduledTransactionDate'
      return this._restService.Post(_url,request);
    }
  Getinactiveusers(request:any){
    let _url = `/Report/GetDormantAccountsByCompany?CompanyId=${request.CompanyId}&PointAccountId=${request.PointAccountId}&Months=${request.Months}`
    return this._restService.Get(_url);
  }
  ProcessCommissionPayoutByIDs(request){
    let _url = `/CommssionPayout/ProcessCommissionPayoutByIDs?${request}`
    return this._restService.Post(_url,null);
  }
  ExportUpcomingScheduleTransactionsReport(request){
    let _url = `/Report/ExportUpcomingScheduleTransactionsReport?companyId=${request.CompanyId}&pointAccountId=${request.PointAccountIDs}&search=${request.Search}&timeLineType=${request.TimeLineType}&from=${request.From}&to=${request.To}&payoutType=${request.payoutType}`
    return this._restService.Getblob(_url);
  }
  ExportExecutedScheduleTransactionsReport(request){
    let _url = `/Report/ExportExecutedScheduleTransactionsReport?companyId=${request.CompanyId}&pointAccountId=${request.PointAccountIDs}&search=${request.Search}&timeLineType=${request.TimeLineType}&from=${request.From}&to=${request.To}&payoutType=${request.payoutType}`
    return this._restService.Getblob(_url);
  }
  saveFile(blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) { 
        navigator.msSaveBlob(blob, fileName);
    } else { 
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    }
  }
  GetCommissionPayoutmanualBatchList(request){
    let _url = `/CommssionPayout/GetCommissionPayoutmanualBatchList?${request}`
    return this._restService.Get(_url);
  }
  ProcessCommissionPayoutmanual(request){
    let _url = `/CommssionPayout/ProcessCommissionPayoutmanual?${request}`
    return this._restService.Post(_url,null);
  }
  GetCommissionPayoutmanualList(request){
    let _url = `/CommssionPayout/GetCommissionPayoutmanualList?${request}`
    return this._restService.Get(_url);
  }
  ProcessCommissionPayout(request){
    let _url = `/CommssionPayout/ProcessCommissionPayout?${request}`
    return this._restService.Post(_url,null);
  }
  GetCommissionPayoutBatchList(request){
    let _url = `/CommssionPayout/GetCommissionPayoutBatchList?${request}`
    return this._restService.Get(_url);
  }
  getCompanySettingActivityLog(request:any){
    let _url = `/ActivityLog/GetCompanySettingActivityLog`
    return this._restService.Post(_url,request);
  }
  GetCommissionPayoutList(request){
    let _url = `/CommssionPayout/GetCommissionPayoutList?${request}`
    return this._restService.Get(_url);
  }
  getReportDetails(request:any){
    let _url = `/Tax1099/GetRecipientReportDetails`
    return this._restService.Post(_url,request);
  }
  GetCompanyDetails() {
    let _url = '/Company/GetCompanyDetail';
    return this._restService.Get(_url);
}
  exportTaxStatusReport(request:any){
    let _url = `/Tax1099/ExportRecipientReportDetails?CompanyId=${request.companyId}&Skip=${request.skip}&Take=${request.take}&search=${request.search}`
    return this._restService.Getblob(_url);
  }
  ExportDormatAccountsByCompany(request:any){
    let _url = `/Report/ExportDormantAccountsByCompany?CompanyId=${request.CompanyId}&PointAccountId=${request.PointAccountId}&Months=${request.Months}`
    return this._restService.Getblob(_url);

  }
  FindDuplicateTransfers(request){
    let _url = `/CustomerPointTransactions/FindDuplicateTransfers?companyId=${request.companyId}&startDate=${request.startDate}&endDate=${request.endDate}`;
    return this._restService.Get(_url);
  }
  GetSQSReport(request){
    let _url = `/Report/GetSQSReport`;
    return this._restService.Post(_url, request);
  }
  GetCardOrders(request){
    let _url = `/v1/CardOrder/GetCardOrders`;
    return this._restService.Post(_url, request);
  }
  UpdateCardOrders(request){
    let _url = `/v1/CardOrder/UpdateCardOrders`;
    return this._restService.Post(_url, request);
  }
  FindSuspiciousTransfers(request){
    let _url = `/Report/GetTransactionsAlertReport?fromDate=${request.fromDate}&transactionNumber=${request.transactionNumber}&mailSend=${request.mailSend}`;
    return this._restService.Get(_url);
  }
  GetDormantReport(request){
    let _url = `/Report/GetDormantReport?${request}`;
    return this._restService.Get(_url);
  }
  ExportDormantReport(request:any){
    let _url = `/Report/ExportDormantReport?${request}`
    return this._restService.Getblob(_url);
  }
  ExportFeeReportSummary(request:any){
    let _url = `/Report/ExportFeeReportSummary?${request}`
    return this._restService.Getblob(_url);
  }
  ExportFeeReport(request:any){
    let _url = `/Report/ExportFeeReport?${request}`
    return this._restService.Getblob(_url);
  }
}
