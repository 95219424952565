import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {RestService} from '../../../src/app/shared/rest.service';
import {UserService} from './user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private verifyUserIntervalId: any = null;

  constructor(private _router: Router,private _restService:RestService,private _userService: UserService) { }

  sendToken(token: any) {
    token.loggedInDate=new Date();
    localStorage.setItem("LoggedInUser",JSON.stringify(token))
    this.startVerifyUser();
  }
  sendAdminToken(token: any) {
    token.loggedInDate = new Date();
    localStorage.setItem("LoggedInAdmin", JSON.stringify(token))
  }
  sendCompanyToken(token: any) {
    token.loggedInDate = new Date();
    localStorage.setItem("LoggedInCompany", JSON.stringify(token))
  }
  // sendUserData(data: string){
  //   localStorage.setItem("LoggedInUserData",data)
  // }
  sendUserData(data: string): void {
    this._userService.setActiveUser(data);
  }

  setAdminBackToUrl(data: any) {
    window.localStorage.setItem('AdminBackToUrl', data);
  }
  getAdminBackToUrl() {
    return localStorage.getItem("AdminBackToUrl");
  }
  setCompanyBackToUrl(data: any) {
    window.localStorage.setItem('CompanyBackToUrl', data);
  }
  getCompanyBackToUrl() {
    return localStorage.getItem("CompanyBackToUrl");
  }
  isAuthorised(){
    if(this.getToken()){
      var token=JSON.parse(this.getToken());
      var loggedInDate=new Date(token.loggedInDate);
      loggedInDate.setSeconds(loggedInDate.getSeconds() + token.expires_in);
      if(loggedInDate < new Date()) return true;
      else return false;
    }
    return false;
  }

  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  getAdminToken() {
    return localStorage.getItem("LoggedInAdmin")
  }
  getCompanyToken() {
    return localStorage.getItem("LoggedInCompany")
  }
  getUserData(){
    return localStorage.getItem("LoggedInUserData")
  }


  isLoggedIn() {
    return this.getToken() !== null;
  }
  isAdminLoggedIn() {
    return this.getAdminToken() !== null;
  }
  isCompanyLoggedIn() {
    return this.getCompanyToken() !== null;
  }
  sendLanguageMenu(data: any){
    localStorage.setItem("LanguageMenuList",data)
  }
  getLanguageMenu() {
    return JSON.parse(localStorage.getItem("LanguageMenuList"));
  }

  sendDefaultCountryCode(data: any){
    localStorage.setItem("DefaultCountryCode",data)
  }
  sendDefaultLanguageCountryCode(data: any){
    localStorage.setItem("DefaultLanguageCountryCode",data)
  }
  logout() {
    this.stopVerifyUser();
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("LoggedInUserData");
    localStorage.removeItem("LanguageMenuList");
    localStorage.removeItem("DefaultCountryCode");
    localStorage.removeItem("DefaultLanguageCountryCode");
    localStorage.removeItem("isImpersonate");
    localStorage.removeItem("superAdmin");
    if(location.pathname != '/ForgotPassword'){
      this._router.navigate(["login"]);
    }
  }
  setCompanyLogo(data){
    var _url ="/Company/SetCompanyLogo";
    return this._restService.PostFile(_url,data);
}

  sendCompanyProfileLogo(data: string): void {
    this._userService.setCompanyFavIcon(data);
  }

  startVerifyUser() {
    this.stopVerifyUser(); // Clear any existing interval to prevent duplicates
    if (this.isLoggedIn()) {
      this.verifyUserIntervalId = setInterval(() => {
        this.VerifyUser();
      }, 10000); // Call every 10 seconds
    }
  }

  stopVerifyUser() {
    if (this.verifyUserIntervalId) {
      clearInterval(this.verifyUserIntervalId);
      this.verifyUserIntervalId = null;
    }
  }

  VerifyUser() {
    this._restService.VerifyUser().subscribe((res: any) => {
      if (res == false) {
        this.logout();
      }
  })
  }

}
